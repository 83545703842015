import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Success.scss';

const Success = () => {
  const navigate = useNavigate();

  return (
    <div className='successPage'>
      <h1 className='successPage-title'>Form Submitted Successfully!</h1>
      <button className='base_btn btn_md primary_btn' onClick={() => navigate('/customer')}>Submit Another Response</button>
    </div>
  );
};

export default Success;
