import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.scss';

const NotFound = () => {
  return (
    <div className='notFound'>
      <p className='notFound-title'>404</p>
      <p className='notFound-para'>The page you're looking for does not exist</p>
      <Link className='notFound-action' to="/customer" title="Back To Form">Back To Form</Link>
    </div>
  )
}

export default NotFound
