import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './Layout';
import { Login, Customer, Success, NotFound } from "./pages";
import { PrivateRoute } from './components';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index path='/' element={<Login />} />
        <Route path='/customer' element={<PrivateRoute><Customer /></PrivateRoute>} />
        <Route path='/success' element={<PrivateRoute><Success /></PrivateRoute>} />
        <Route path='*' element={<PrivateRoute><NotFound /></PrivateRoute>} />
      </Route>
    </Routes>
  );
}

export default App;
