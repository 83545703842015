import React from 'react';
import './Heading.scss';

const Heading = (props) => {
  return (
    <div className='headingBox'>
        <p className="headingBox--title">{props.title}</p>
    </div>
  )
}

export default Heading
