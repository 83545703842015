import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../features/LoginSlice';
import { resetForm } from '../../features/FeedbackFormSlice';
import { horizontalLogo } from '../../constants';
import { toast } from 'react-toastify';
import './Header.scss';

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { agents, isLoggedIn } = useSelector((state) => state.login);

  const handlerLogout = () => {
    dispatch(logout()); // Dispatch the logout action
    dispatch(resetForm());
    toast.success('Agent Logged Out'); // Show success toast
    navigate('/'); // Redirect to index page
  };

  return (
    <header className="header">
      <div className="header-left">
        <Link to="/" title="Candere Lifestyle Jewellery" className="header--logo">
          <img src={horizontalLogo} alt="Candere Lifestyle Jewellery" className="img-fluid" />
        </Link>
      </div>
      <div className="header-center"></div>
      <div className="header-right">
        <p className="header--user">
          {isLoggedIn && agents.length > 0 ? agents[0]?.agent_code : 'Guest'}
          <span className="header--user-action" onClick={handlerLogout}>
            Logout
          </span>
        </p>
      </div>
    </header>
  );
};

export default Header;
