import { createSlice } from '@reduxjs/toolkit';

// Load initial state from localStorage
const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem('loginState');
    return serializedState ? JSON.parse(serializedState) : { agents: [], isLoggedIn: false };
  } catch (error) {
    console.error('Error loading state from localStorage:', error);
    return { agents: [], isLoggedIn: false };
  }
};

// Save state to localStorage
const saveToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('loginState', serializedState);
  } catch (error) {
    console.error('Error saving state to localStorage:', error);
  }
};

// Remove state from localStorage
const clearLocalStorage = () => {
  try {
    localStorage.removeItem('loginState');
  } catch (error) {
    console.error('Error clearing state from localStorage:', error);
  }
};

const initialState = loadFromLocalStorage();

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setAgents: (state, action) => {
      state.agents = action.payload;
      saveToLocalStorage(state);
    },
    loginSuccess: (state) => {
      state.isLoggedIn = true;
      saveToLocalStorage(state);
    },
    logout: (state) => {
      state.agents = [];
      state.isLoggedIn = false;
      clearLocalStorage();
    },
  },
});

export const { setAgents, loginSuccess, logout } = loginSlice.actions;
export default loginSlice.reducer;
