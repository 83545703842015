import React from "react";
import './ProgressBar.scss';

const ProgressBar = ({ progress }) => {
  return (
    <div className="progressBar">
      <div className="progressBar--main"style={{ width: `${progress}%`}}></div>
    </div>
  );
};

export default ProgressBar;
