import React from 'react';
import { FeedbackForm } from '../../components';

const Customer = () => {
  return (
    <>
      <FeedbackForm />
    </>
  )
}

export default Customer
